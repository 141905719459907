@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
// @import url(./assets/fonts/Rodetta.otf);

@font-face {
  font-family: "Rodetta";
  src: url(./assets/fonts/Rodetta.otf);
}

// @layer base {
//   html {
//     font-family: "Roboto", sans-serif;
//   }
// }

.about-section {
  .greet-text {
    background: linear-gradient(
      180deg,
      #fba346 -14.71%,
      rgba(70, 88, 251, 0.5) 194.12%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    line-height: 34px;
  }
}
